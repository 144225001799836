import React from "react";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import flatten from "flat";
import merge from "lodash/merge";
import enAboutContent from "../../content/home/about/en.json";
import enClientsContent from "../../content/home/clients/en.json";
import enContactContent from "../../content/home/contact/en.json";
import enServicesContent from "../../content/home/services/en.json";
import enTeamContent from "../../content/home/team/en.json";
import enValuesContent from "../../content/home/values/en.json";
import deAboutContent from "../../content/home/about/de.json";
import deClientsContent from "../../content/home/clients/de.json";
import deContactContent from "../../content/home/contact/de.json";
import deServicesContent from "../../content/home/services/de.json";
import deTeamContent from "../../content/home/team/de.json";
import deValuesContent from "../../content/home/values/de.json";
import enCommonContent from "../../content/common/en.json";
import deCommonContent from "../../content/common/de.json";
import theme from "../../theme";
import Locale from "../../types/Locale";
import GlobalHead from "../../components/GlobalHead";
import GlobalStyle from "../../components/GlobalStyle";
import Home from "../../components/Home";
import ImagesContext from "../../helpers/ImagesContext";
import useImages from "../../helpers/useImages";
import withNetlifyIdentity from "../../helpers/withNetlifyIdentity";

const content = flatten(
  merge(
    {},
    enAboutContent,
    enClientsContent,
    enServicesContent,
    enTeamContent,
    enContactContent,
    enValuesContent,
    deAboutContent,
    deClientsContent,
    deServicesContent,
    deTeamContent,
    deContactContent,
    deValuesContent,
    { common: { ...enCommonContent } },
    { common: { ...deCommonContent } }
  )
);

const HomePage: React.FC = () => {
  const images = useImages();

  return (
    <IntlProvider
      defaultLocale={Locale.de}
      locale={Locale.de}
      messages={content}
    >
      <ThemeProvider theme={theme}>
        <ImagesContext.Provider value={images}>
          <GlobalHead locale={Locale.de} />
          <GlobalStyle />
          <Home />
        </ImagesContext.Provider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default withNetlifyIdentity(HomePage);
