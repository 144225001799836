import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

// eslint-disable-next-line @typescript-eslint/no-explicit-any, react/display-name
const withNetlifyIdentity = (Component: React.FC<any>) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  props: any
): React.ReactElement => {
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      typeof (window as any).netlifyIdentity !== "undefined"
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).netlifyIdentity.on("init", (user) => {
        if (!user) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).netlifyIdentity.on("login", () => {
            document.location.href = "/admin/";
          });
        }
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <script src="https://identity.netlify.com/v1/netlify-identity-widget.js" />
      </Helmet>
      <Component {...props} />
    </>
  );
};

export default withNetlifyIdentity;
