import React from "react";
import Nav from "../Nav";
import Footer from "../Footer";
import Services from "./Services";
import Values from "./Values";
import Clients from "./Clients";
import Team from "./Team";
import About from "./About";
import Contact from "./Contact";

const Home: React.FC = () => (
  <>
    <Nav index />
    <Services />
    <Values />
    <Clients />
    <Team />
    <About />
    <Contact />
    <Footer index />
  </>
);

export default Home;
